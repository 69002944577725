@import "../../styles/variables";


.MuiButton-containedPrimary {
  background-color: $Black300 !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiPopover-paper {
  background-color: $Black300 !important;

  * {
    color: white;
    font-family: $font !important;
    font-size: 16px !important;
    letter-spacing: normal;
  }

  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      > span {
        padding: 4px;
        margin-right: 7px;
      }
    }
  }

  .MuiCheckbox-root {
    svg.MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
}

a.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

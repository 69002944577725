@import "../variables";

button {
  &.panic {
    width: 394px;
    padding: 2px 43px 6px 62px;
    border-radius: 26.5px;
    background-color: $panic;
  }
}

@import "globals/typography";

$fallback: sans-serif;
$font-family-material-ui: $font;

$Green: #00C98D;
$Candela: #295DFF;
$Black300: #252424;
$Black: black;
$Black700: rgba(black, 0.7);
$Black100: rgba(black, 0.1);
$TextBlack: $Black;
$Gray: #B7B7B7;
$Panic: #d50001;

$MobileWidth: 414px;
$DesktopWidth: 1024px;
$DesktopLargeWidth: 1280px;
$Background: #EAEAEA;

$success: #04ae05;
$warning: orange;
$panic: #d50001;

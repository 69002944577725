
$white-color: #fff;
$width-max-name: max-device-width;
$width-min-name: min-device-width;
$target-width: 640px;
$target-width-small: 320px;

.role-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

}

.role-item-header {
  // min-width: 100%;
  flex: 1 100%;
  margin: auto;
}

.role-item {
  margin: 0 auto;
  flex-grow: 1;
  flex-shrink: 1;

}

.role-card-wrapper {
  padding: 16px;

  width: 100%;
}

.role-table-id-column {
  width: 20px;
}

@media screen and ($width-min-name: $target-width) {

  .role-users {
    order: 3;
  }

  .role-permissions {
    order: 2;
  }

  .role-item {
    flex-basis: 40%;
    min-width: 288px;
  }

}

@media screen and ($width-max-name: $target-width) {
  .role-users {
    order: 2;
  }

  .role-table-id-column {
    display: none;
  }

  .role-permissions {
    order: 3;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  height: 100%;
  overflow: auto;
}

html {
  overflow: hidden;
  height: 100%;
}
.ui.dropdown:not(.button)>.default.text {
  color: #777;
}
.countryDropdown{
  min-width: 0px !important;
  padding: 0px !important;
  border: 0px !important;
  width: 0px !important;
}
.countryDropdown > div > div.visible.menu.transition {
  width: 300px;
  margin-left: 8px;
}
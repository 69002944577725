@import '../../../styles/globals/breakpoints';
@import '../../../styles/variables';
@import "../../../styles/globals/typography";

main.screen {
  min-height: 100%;
  padding: 39px 30px;

  .screen-detail {
    &-inner {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include breakpoint(tablet-m, min) {
        gap: 21px;
      }

      .column {
        width: 100%;
        flex: 1 0 25%;
        @include breakpoint(tablet-m, min) {
          max-width: 33%;
        }

        &-inner {
          margin-bottom: 21px;

          .MuiCard-root {
            padding: 30px 24px;

            .MuiCardHeader-root {
              @extend %Interface-bold;
              padding: unset;
              font-size: 18px;

              .MuiTypography-body2 {
                font-size: 18px;
              }
            }

            .MuiCardContent-root {
              @extend %Interface;
              padding: unset;
            }
          }
        }
      }
    }
  }
}


.table {
  &-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    div {
      flex: 1 0 50%;
      border-right: 1px solid rgba(#979797, 0.2);
      padding: 13px 9px 5.5px 10px;
      line-height: 24px;

      &:nth-last-of-type(even),
      &:last-child {
        border-right: none;
      }

      > span {
        display: block;
        width: 100%;
        opacity: 0.7;
        font-size: 14px;
      }
    }
  }
}

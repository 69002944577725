@import 'globals/breakpoints';
@import 'globals/typography';
@import 'layout/buttons';
@import "variables";
@import "map";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 100%;
}

* {
  ::before,
  ::after {
    box-sizing: inherit;
  }
}


// change the pale yellow autocomplete background color to white
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
}

.card-wrapper {
  margin: auto;
  max-width: 100%;
}

.padding-box {
  padding: 32px;
}

#header {
  h1 {
    height: 100% !important;
  }
}

.overlays {
  right: 20px !important;
  bottom: 20px !important;
}

@import '../../../../../../../styles/variables';

.create-group-card-wrapper {
  width: 800px;
}


$width-min-name: min-device-width;

@media screen and (max-device-width: $MobileWidth) {


  .create-group-textfield-wrapper {
    padding: 8px 24px;
  }


}
// ~/ resolves to the home directory (https://github.com/webpack-contrib/sass-loader)
@import '../../../../../../styles/_variables.scss';

$scale: 0.5;
$width: 100 * $scale;
$height: 29 * $scale;
$perspective: 10deg;
$depth: 1em;
$offset: $depth / 2;
$border: 1.5px solid $TextBlack;
$border: 0 0 0 2px $TextBlack;
$borderType: box-shadow;
$bgColor: #EFEFEF;

.bar {
  position: relative;
  width: #{$width}vw;
  height: #{$height}vw;
  display: block;
  background: $bgColor;
  background: #FFFFFF;
  #{$borderType}: $border;
  margin: 10px;
  left: -($depth/2);

  &__specs {
    @extend %Interface-bold;
    margin-left: 2.5%;
    font-size: 20px;
    line-height: 24px;

    h6 {
      margin: 0;
      font-size: 14px;
      font-weight: 300;
    }

    &--top {
      display: block;
      width: 100%;
      border-bottom: 1px solid rgba(#979797, 0.3);
      padding-bottom: 15px;
    }

    &--bottom {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      gap: 20px;

      > div {
        min-width: 25%;
        @include breakpoint(desktop-m, min) {
          min-width: 122px;
        }
      }
    }
  }

  &:after {
    content: '';
    display: block;
    margin: 7.5px auto;

    align-self: center;
    border: 2px solid black;
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    background: white;
  }
}

@import "src/styles/variables";
@import "src/styles/globals/typography";

.MuiAppBar-colorPrimary {
  background-color: $Black300 !important;
}

.route--active {
  font-weight: bold;
  text-decoration: underline;
}

.search--bar {
  &.dark {
    display: flex;
    color: black;
  }
}


.MuiInputBase-input {
  font: normal 16px/24px $font !important;
  color: white;

  &::placeholder {
    color: white;
  }
}

.menu-items {
  padding-top: 15px;

  li.MuiListItem-button, a {
    font-family: $font;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    padding: 0;
    width: 100%;
    display: block;
    color: $Black300;

    &:hover {
      background-color: transparent;
      color: rgba($Black300, 0.5);
    }
  }
}

.search--bar {
  .card-btn {
    width: 44px;
    height: 44px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 22px;
      width: 22px;
    }

    &.light {
      background-color: #E9E9E9;
    }

    &.dark {
      background-color: rgba(white, 0.1);

    }
  }
}

.MuiInputBase-root {
  &.dark {
    color: white;
  }

  &.light {
    color: $Black300;
    opacity: 1;
  }
}

.MuiDrawer-paperAnchorRight {
  & input[type=search], img {
    display: none;
  }
}

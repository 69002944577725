@import "src/styles/_variables.scss";
@import "src/styles/globals/breakpoints.scss";

.datetime {
  display: flex;
  max-width: 100%;

  &-selection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include breakpoint(tablet-m, min) {
      gap: 21px;
      width: 100% !important;
    }

    > div {
      flex: 1 0 24%;
      border-right: 1px solid rgba(#979797, 0.2);

      > div[style] {
        width: 94px !important; // overwrite inline styling
        border-bottom: none;

        label {
          font-weight: bold !important;
          color: black !important;
          font-size: 14px;
        }

        input[type=text] {
          font-size: 14px !important;
          font-weight: 300 !important;
          color: $Black700 !important;
        }
      }

      > div {
        // remove hr from date and time picker
        hr {
          display: none;
        }
      }
    }
  }
}

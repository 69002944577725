.rv-discrete-color-legend-item__color {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  display: inline-block;
  margin-left: 10px;
}

.rv-crosshair__line {
  // create a dashed stroke
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3) 70%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0));
  background-size: 100% 20px;
}

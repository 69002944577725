@import '../../../../../../../styles/variables';

.edit-user-card-wrapper {
  width: 800px;
}


@media screen and (max-device-width: $MobileWidth) {


  .edit-users-textfield-wrapper {
    padding: 8px 24px;
  }

}

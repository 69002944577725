@import '../../../styles/variables';
@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';

.login-wrapper {
  background-size: 100%!important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &-inner {
    width: 100%;
    max-width: 480px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    text-align: center;
    @include breakpoint(mobile-xxl, max) {
      height: 100vh;
    }

    h1 {
      @extend %Didot;
      font-size: 43px;
      line-height: 40px;
      padding-bottom: 28px;
    }

    p {
      @extend %Interface;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      max-width: 366px;
      margin: auto;
    }

    form {
      * {
        @extend %Interface;
        font-size: 16px;
        color: #5A5A5F;
        text-align: left;
      }

      button.flat-button {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          background-color: transparent !important;
        }
      }

      button,
      .MuiButtonBase-root {
        width: 100%;
        display: flex;
        align-items: center;
        text-transform: none !important; // overwrite default
        &.forgot-password {
          line-height: 24px;

          & span {
            line-height: 24px;
            text-decoration: underline;
          }
        }

        .MuiButton-label > span {
          font-weight: bold;
        }

        // Disabled
        &.Mui-disabled,
        &:disabled {
          background-color: transparent;

          .MuiButton-label > span {
            color: rgba($TextBlack, 0.22);
          }
        }

        .icon {
          background-color: white;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
          margin-left: 18px;

          &.Mui-disabled,
          &:disabled {
            box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
          }

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }

      .MuiFormControl-fullWidth {
        margin-bottom: 15px;

        .MuiFilledInput-root {
          background-color: #DDDDE0;
          border-radius: 5px !important;

          &:before {
            border-bottom: none;
          }
        }
      }
    }
  }

}

@import '../../../../../../styles/variables';

.rv-discrete-color-legend {
  &.vertical {
    display: flex;

    .rv-discrete-color-legend-item__color {
      width: 21px;
      border-radius: 0;

      .rv-discrete-color-legend-item__color__path {
        stroke-width: 3px;
      }

      .rv-discrete-color-legend-item {
        color: rgba(black, 0.7) !important;
      }
    }
  }
}

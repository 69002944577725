
@import '../../styles/variables';

$removeColumn4Width: 786px;
$removeColumn3Width: 624px;
$removeColumn2Width: 486px;

@media screen and ( max-device-width: $MobileWidth) {

  .edit-table-id-column {
    display: none;
  }
   .edit-table-secondary-column {
    display: none;
  }

}

.edit-table-id-column {
  width: 20px;
}

@media screen and ( max-device-width: $removeColumn4Width) {
  .edit-table-column-4 {
    display: none;
  }
}

@media screen and ( max-device-width: $removeColumn3Width) {
  .edit-table-column-4 {
    display: none;
  }
  .edit-table-column-3 {
    display: none;
  }
}

@media screen and ( max-device-width: $removeColumn2Width) {
  .edit-table-column-4 {
    display: none;
  }
  .edit-table-column-3 {
    display: none;
  }
  .edit-table-column-2 {
    display: none;
  }
}
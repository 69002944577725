@import '../../../../../styles/variables';

.users-flex-container {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 4px;
}

.users-role {
  text-align: right;
  flex-grow: 1;
  color: $Candela;
  font-weight: 300;
  font-stretch: condensed;
  font-size: small;
  text-transform: uppercase;
  font-family: $font;
}

.users-username {
  flex-grow: 1;
  font-size: x-large;
  line-height: 32px;
  font-weight: 300;
  color: $TextBlack;
  font-family: $fancy-font;
}

.users-title-company {
  padding-top: 2px;
  flex-basis: 100%;
  flex-grow: 2;
  font-size: medium;
  color: $Gray;
  font-weight: 300;
  font-stretch: condensed;
  font-family: $font;
}

.flex-child-div > div {
  display: flex;
}

.ackButton {
  min-width: 49% !important;
}

.solveButton {
  //display: none !important;
  margin-left: 2%;
  min-width: 49% !important;
}

.incidentItem:last-of-type {
  margin-bottom: 40px;
}

.screen-grid-title {
  //color: black !important;
}
.sender-box-wrapper{
  text-align:center;
}
.usb-switcher-indicator{
  display:flex;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:100%;
  h4{
    margin:0;
  }
}
.sender-box-button-wrapper{
  margin-top: 15px;
}
.switcher-button{
  background-color: #d50001;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #ce0000;
  }

  transition: background-color 0.5s;
  user-select: none;
  border-radius: 26.5px;
  height:41px;
  margin: auto;
  display:flex;
  align-items:center;
  justify-content:center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

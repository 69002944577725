@import '../../../../../../../styles/variables';
$white-color: #fff;

.group-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.group-item-header {
  flex: 1 100%;
  margin: auto;
}

.group-item {
  margin: 0 auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.group-card-wrapper {
  padding: 16px;
  width: 100%;
}

@media screen and (min-width: $DesktopLargeWidth) {

  .group-users {
    order: 3;
  }

  .group-screens {
    order: 2;
  }

  .group-item {
    flex-basis: 40%;
    min-width: 288px;
  }

}

@media screen and (max-device-width: $MobileWidth) {
  .group-users {
    order: 2;
  }
  .group-item {
    min-width: 0;
    flex: 1 100%;
    margin: auto;
  }

  .group-screens-secondary-column {
    display: none;
  }

  .group-screens {
    order: 3;
  }
}

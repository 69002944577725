.core-layout__viewport {
  -webkit-overflow-scrolling: touch;
  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    max-height: calc(100vh - 160px);
  }
  @supports not (-webkit-overflow-scrolling: touch) {
  /* CSS for other than iOS devices */
    height: 100%;
  }
  /* height: auto; */
  //overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 64px);
}

.overlays {
  width: 100%;
  height: 100%;

  .mapboxgl-marker{
    left: -5px;
    top: -45px;
  }

  .mapboxgl-popup {
    cursor: default;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group:first-child {
    position: absolute;
    top: 20px;
    bottom: unset;
  }

  .mapboxgl-popup-content {
    padding: 0;

    .mapboxgl-popup-close-button {
      width: 32px;
      height: 32px;
      background: white;
      border-radius: 50%;
      font-size: 21px;
      margin: 10px;
      padding-bottom: 5px;
    }

    .popup-container {
      border-radius: 5px;
      min-height: 435px;
      width: 385px;
      background-color: white;

      .text-container {
        padding: 25px 25px 0 25px;

        .title-text {
          display: flex;
          place-content: space-between center;
          align-items: center;
          width: 100%;

          .name {
            font-size: 30px;
            font-family: $fancy-font;
          }

          .temp {
            font-size: 30px;
            font-family: $fancy-font;
            margin-left: auto;
            margin-top: 0;
            margin-bottom: 1rem;
          }
        }

        .city {
          font-size: 16px;
          font-weight: normal;
          font-family: $font;
        }

        .status {
          display: flex;
          margin-bottom: 25px;
          align-items: center;

          &.ok {
            color: $success;
          }

          &.error {
            color: $panic;
          }

          img {
            margin-right: 5px;
          }
        }

        .buttons {
          border-top: 1px solid $Black100;
          min-height: 85px;
          align-items: center;
          display: flex;
          width: 100%;
          justify-content: space-between;

          .panic {
            cursor: pointer;
            font-family: $font;
            font-weight: bold;
            width: 162px;
            height: 40px;
            font-size: 16px;
            border-radius: 26.5px;
            background-color: $Panic;
            color: white;
            border: $Panic;
            padding: 0;
          }

          .location {
            cursor: pointer;
            font-family: $font;
            font-weight: bold;
            width: 162px;
            height: 40px;
            font-size: 16px;
            border-radius: 26.5px;
            background-color: $Black;
            color: white;
            border: $Black;
            padding: 0;
          }
        }
      }
    }
  }
}

@import '../../../../../styles/variables';


.group-wrapper {
  padding: 4px;
}

.groups-name {
  font-size: x-large;
  line-height: 32px;
  font-weight: 300;
  color: $TextBlack;
}

@import 'breakpoints';

// Interface and Didot from Adobe Fonts
@import url("https://use.typekit.net/srd3wih.css");

$font: 'Interface', sans-serif;
$fancy-font: 'Linotype-Didot', serif;
/* -------------------------------------------------------------------------- */
/*	Font placeholders
/* -------------------------------------------------------------------------- */
%Interface {
  font-family: $font;
  font-weight: normal;

  &-light {
    font-family: $font;
    font-weight: 300;
  }

  &-bold {
    font-family: $font;
    font-weight: bold;
  }
}

%Didot {
  font-family: $fancy-font;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
}

h1 {
  font-family: $fancy-font;
  margin: unset !important;
  padding: 0;
  line-height: normal;
}

h5 {
  font-family: $font !important; // overwrite default
  font-weight: bold;
  font-size: 18px !important;
  line-height: normal;
}

p {
  font-family: $font !important;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 1em;
}

.title {
  @extend %Didot;
  font-size: 24px;
  line-height: 38px;
}

.temp {
  @extend %Didot;
  font-size: 32px;
  line-height: normal;
}

/* -------------------------------------------------------------------------- */
/*	Typography
/* -------------------------------------------------------------------------- */
.MuiCardHeader-root {
  * > {
    @extend %Interface-bold;
  }

  .MuiTypography-h5 {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 20px;
  }
}

.MuiCardContent-root {
  > div {
    @extend %Interface;
    font-size: 14px;

    p {
      line-height: 26px;
      margin-bottom: 1em;
      color: rgba(37, 36, 36, 0.7);
    }
  }
}

a {
  &:hover {
    color: rgba(#252424, 0.5) !important;
  }
}
